import router from "@/router";
import { Get } from "./Fetch";
import { urls } from "./Apis";

export function SvgToImg(svg_string, callback) {
  const url = URL.createObjectURL(new Blob([svg_string], {type: 'image/svg+xml'}));
  const imgTemp = document.createElement('img');
  document.body.appendChild(imgTemp);
  imgTemp.onload = () => {
    const canvas = document.createElement('canvas');
    // canvas.width = (imgTemp.clientHeight>imgTemp.clientWidth?imgTemp.clientHeight:imgTemp.clientWidth)+10;
    // canvas.height = (imgTemp.clientHeight>imgTemp.clientWidth?imgTemp.clientHeight:imgTemp.clientWidth)+10;
    const hip=Math.sqrt(Math.pow(imgTemp.clientHeight,2)+Math.pow(imgTemp.clientWidth,2))
    canvas.width = hip
    canvas.height = hip
    const canvasCtx = canvas.getContext('2d');
    canvasCtx.drawImage(
      imgTemp,
      (canvas.width/2)-(imgTemp.clientWidth/2), 
      (canvas.height/2)-(imgTemp.clientHeight/2)
    )
    const imgData = canvas.toDataURL('image/png');
    
    callback(imgData)
    
    URL.revokeObjectURL(url);
    document.body.removeChild(imgTemp);
  };
  imgTemp.src = url;
}

export function GetCookie(key) {
  const cookieValue = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
  return cookieValue ? cookieValue.pop() : null;
}

export function SetCookie(key, value) {
  document.cookie = `${key}=${value};`;
}

export function GetParamFromUrl(key, formated=true) {
  const value=router.currentRoute.value.params[key]
  if(value)
    return formated?value.replaceAll('-', ' '):value;
  else
    return '';
}

export async function GetAssociationFromUrl() {
  let association=null;
  await Get(
      `${urls.Gremial}/byName/${GetParamFromUrl('gremial_name')}`,
      (data)=>{
        association=data;
      },
      (err)=>{
        console.error("error:", err);
        alert("Error al obtener la association");
      }
  )
  return association;
}

export async function GetLineFromUrl() {
  let line={};
  await Get(
      `${urls.Line}/byFullName/${GetParamFromUrl('line_name')}`,
      (data)=>{
        line=data;
      },
      (err)=>{
        console.error("error:", err);
        alert("Error al obtener la linea");
      }
  )
  return line;
}